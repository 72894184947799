import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// ROTAS DE CONTROLE
import Page404 from '../pages/Controller/Page404';

// ROTAS DE AUTENTICAÇÃO
import Login from '../pages/Authentication/Login';
import Recover from '../pages/Authentication/Recover';

// ROTAS DO SISTEMA
    // DASHBOARD
    import Dashboard from '../pages/System/Dashboard/Dashboard';
    // HOME
    import Home from '../pages/System/Main/Controller';
    import HomePath from '../pages/System/Main/ControllerPath';
    import HomeRealtime from '../pages/System/Main/HomeRealtime';
    // INSPECTOR
    import InspectorChoose from '../pages/System/Inspector/Choose';
    import InspectorCollect from '../pages/System/Inspector/Collect';
    import InspectorScanner from '../pages/System/Inspector/Scanner';
    import InspectorWrite from '../pages/System/Inspector/Write';
    import InspectorStep from '../pages/System/Inspector/Step';
    import InspectorDatehour from '../pages/System/Inspector/Datehour';
    import InspectorDetail from '../pages/System/Inspector/Detail';
    import InspectorBreakdown from '../pages/System/Inspector/Breakdown';
    import InspectorBreakdownAdd from '../pages/System/Inspector/BreakdownAdd';
    import InspectorBreakdownEdit from '../pages/System/Inspector/BreakdownEdit';
    // ENVIROMENT
    import EnviromentList from '../pages/System/Enviroment/List';
    import EnviromentTrash from '../pages/System/Enviroment/Trash';
    import EnviromentAdd from '../pages/System/Enviroment/Add';
    import EnviromentView from '../pages/System/Enviroment/View';
    import EnviromentEdit from '../pages/System/Enviroment/Edit';
    // PERMISSION
    import PermissionList from '../pages/System/Permission/List';
    import PermissionTrash from '../pages/System/Permission/Trash';
    import PermissionAdd from '../pages/System/Permission/Add';
    import PermissionView from '../pages/System/Permission/View';
    import PermissionEdit from '../pages/System/Permission/Edit';
    // ACCOUNT
    import AccountList from '../pages/System/Account/List';
    import AccountTrash from '../pages/System/Account/Trash';
    import AccountAdd from '../pages/System/Account/Add';
    import AccountView from '../pages/System/Account/View';
    import AccountEdit from '../pages/System/Account/Edit';
        // ACCOUNT TYPE USER
        import AccountTypeUserList from '../pages/System/Account/TypeUser/List';
        import AccountTypeUserTrash from '../pages/System/Account/TypeUser/Trash';
        import AccountTypeUserAdd from '../pages/System/Account/TypeUser/Add';
        import AccountTypeUserView from '../pages/System/Account/TypeUser/View';
        import AccountTypeUserEdit from '../pages/System/Account/TypeUser/Edit';
        // ACCOUNT USER
        import AccountUserList from '../pages/System/Account/User/List';
        import AccountUserTrash from '../pages/System/Account/User/Trash';
        import AccountUserAdd from '../pages/System/Account/User/Add';
        import AccountUserView from '../pages/System/Account/User/View';
        import AccountUserEdit from '../pages/System/Account/User/Edit';
    // LOCAL READING
    import LocalReadingList from '../pages/System/LocalReading/List';
    import LocalReadingTrash from '../pages/System/LocalReading/Trash';
    import LocalReadingAdd from '../pages/System/LocalReading/Add';
    import LocalReadingView from '../pages/System/LocalReading/View';
    import LocalReadingEdit from '../pages/System/LocalReading/Edit';
    // SUBTYPE READING
    import SubTypeReadingList from '../pages/System/SubTypeReading/List';
    import SubTypeReadingTrash from '../pages/System/SubTypeReading/Trash';
    import SubTypeReadingAdd from '../pages/System/SubTypeReading/Add';
    import SubTypeReadingView from '../pages/System/SubTypeReading/View';
    import SubTypeReadingEdit from '../pages/System/SubTypeReading/Edit';
    // TYPE USER
    import TypeUserList from '../pages/System/TypeUser/List';
    import TypeUserTrash from '../pages/System/TypeUser/Trash';
    import TypeUserAdd from '../pages/System/TypeUser/Add';
    import TypeUserView from '../pages/System/TypeUser/View';
    import TypeUserEdit from '../pages/System/TypeUser/Edit';
    // USER
    import UserList from '../pages/System/User/List';
    import UserTrash from '../pages/System/User/Trash';
    import UserAdd from '../pages/System/User/Add';
    import UserView from '../pages/System/User/View';
    import UserEdit from '../pages/System/User/Edit';
    // STATUS
    import StatusList from '../pages/System/Status/List';
    import StatusTrash from '../pages/System/Status/Trash';
    import StatusAdd from '../pages/System/Status/Add';
    import StatusView from '../pages/System/Status/View';
    import StatusEdit from '../pages/System/Status/Edit';
    // PORT
    import PortList from '../pages/System/Port/List';
    import PortTrash from '../pages/System/Port/Trash';
    import PortAdd from '../pages/System/Port/Add';
    import PortView from '../pages/System/Port/View';
    import PortEdit from '../pages/System/Port/Edit';
    // STEP
    import StepList from '../pages/System/Step/List';
    import StepTrash from '../pages/System/Step/Trash';
    import StepAdd from '../pages/System/Step/Add';
    import StepView from '../pages/System/Step/View';
    import StepEdit from '../pages/System/Step/Edit';
    // PATH
    import PathList from '../pages/System/Path/List';
    import PathTrash from '../pages/System/Path/Trash';
    import PathAdd from '../pages/System/Path/Add';
    import PathView from '../pages/System/Path/View';
    import PathEdit from '../pages/System/Path/Edit';
    // SHIPOWNER
    import ShipownerList from '../pages/System/Shipowner/List';
    import ShipownerTrash from '../pages/System/Shipowner/Trash';
    import ShipownerAdd from '../pages/System/Shipowner/Add';
    import ShipownerView from '../pages/System/Shipowner/View';
    import ShipownerEdit from '../pages/System/Shipowner/Edit';
    // SHIP
    import ShipList from '../pages/System/Ship/List';
    import ShipTrash from '../pages/System/Ship/Trash';
    import ShipAdd from '../pages/System/Ship/Add';
    import ShipView from '../pages/System/Ship/View';
    import ShipEdit from '../pages/System/Ship/Edit';
    // COMPANY
    import CompanyList from '../pages/System/Company/List';
    import CompanyTrash from '../pages/System/Company/Trash';
    import CompanyAdd from '../pages/System/Company/Add';
    import CompanyView from '../pages/System/Company/View';
    import CompanyEdit from '../pages/System/Company/Edit';
    // SEVERITY
    import SeverityList from '../pages/System/Severity/List';
    import SeverityTrash from '../pages/System/Severity/Trash';
    import SeverityAdd from '../pages/System/Severity/Add';
    import SeverityView from '../pages/System/Severity/View';
    import SeverityEdit from '../pages/System/Severity/Edit';
    // OPERATION
    import OperationList from '../pages/System/Operation/List';
    import OperationTrash from '../pages/System/Operation/Trash';
    import OperationAdd from '../pages/System/Operation/Add';
    import OperationView from '../pages/System/Operation/View';
    import OperationEdit from '../pages/System/Operation/Edit';
    import OperationStep from '../pages/System/Operation/Step';
        // PORT
        import OperationPortList from '../pages/System/Operation/Port/List';
        import OperationPortTrash from '../pages/System/Operation/Port/Trash';
        import OperationPortAdd from '../pages/System/Operation/Port/Add';
        import OperationPortView from '../pages/System/Operation/Port/View';
        import OperationPortEdit from '../pages/System/Operation/Port/Edit';
        // CARGO
        import OperationCargoList from '../pages/System/Operation/Cargo/List';
        import OperationCargoTrash from '../pages/System/Operation/Cargo/Trash';
        import OperationCargoAdd from '../pages/System/Operation/Cargo/Add';
        import OperationCargoImport from '../pages/System/Operation/Cargo/Import';
        import OperationCargoView from '../pages/System/Operation/Cargo/View';
        import OperationCargoEdit from '../pages/System/Operation/Cargo/Edit';
    // TYPE CARGO
    import TypeCargoList from '../pages/System/TypeCargo/List';
    import TypeCargoTrash from '../pages/System/TypeCargo/Trash';
    import TypeCargoAdd from '../pages/System/TypeCargo/Add';
    import TypeCargoView from '../pages/System/TypeCargo/View';
    import TypeCargoEdit from '../pages/System/TypeCargo/Edit';
        // CATEGORY
        import CategoryList from '../pages/System/TypeCargo/Category/List';
        import CategoryTrash from '../pages/System/TypeCargo/Category/Trash';
        import CategoryAdd from '../pages/System/TypeCargo/Category/Add';
        import CategoryView from '../pages/System/TypeCargo/Category/View';
        import CategoryEdit from '../pages/System/TypeCargo/Category/Edit';
        // PART
        import PartList from '../pages/System/TypeCargo/Part/List';
        import PartTrash from '../pages/System/TypeCargo/Part/Trash';
        import PartAdd from '../pages/System/TypeCargo/Part/Add';
        import PartView from '../pages/System/TypeCargo/Part/View';
        import PartEdit from '../pages/System/TypeCargo/Part/Edit';
        // BREAKDOWN
        import BreakdownList from '../pages/System/TypeCargo/Breakdown/List';
        import BreakdownTrash from '../pages/System/TypeCargo/Breakdown/Trash';
        import BreakdownAdd from '../pages/System/TypeCargo/Breakdown/Add';
        import BreakdownView from '../pages/System/TypeCargo/Breakdown/View';
        import BreakdownEdit from '../pages/System/TypeCargo/Breakdown/Edit';
        // FIELD
        import FieldList from '../pages/System/TypeCargo/Field/List';
        import FieldTrash from '../pages/System/TypeCargo/Field/Trash';
        import FieldAdd from '../pages/System/TypeCargo/Field/Add';
        import FieldView from '../pages/System/TypeCargo/Field/View';
        import FieldEdit from '../pages/System/TypeCargo/Field/Edit';

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    {/* ROTAS DE AUTENTICAÇÃO */}
                    <Route path="" element={<Login />} />
                    <Route path="recover" element={<Recover />} />

                    {/* ROTAS DO SISTEMA */}
                    <Route path="dashboard" element={ <Dashboard /> } >
                        <Route path="" element={<Home/>} />
                        <Route path="viewpath/:id" element={<HomePath />} />
                        <Route path="inspector" element={<Outlet />}>
                            <Route path=":id" element={<InspectorChoose />} />
                            <Route path=":id/realtime" element={<HomeRealtime />} />
                            <Route path=":id/step/:step" element={<InspectorStep />} />
                            <Route path=":id/datehour" element={<InspectorDatehour />} />
                            <Route path=":id/detail" element={<InspectorDetail />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo" element={<InspectorCollect />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo/scanner" element={<InspectorScanner />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo/write" element={<InspectorWrite />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo/:chassi" element={<InspectorBreakdown />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo/:chassi/add" element={<InspectorBreakdownAdd />} />
                            <Route path=":id/collect/:local/:type/:subtype/:cargo/:chassi/edit/:id" element={<InspectorBreakdownEdit />} />
                        </Route>
                        <Route path="enviroment" element={<Outlet />} >
                            <Route path="" element={<EnviromentList />} />
                            <Route path="add" element={<EnviromentAdd />} />
                            <Route path="trash" element={<EnviromentTrash />} />
                            <Route path=":id" element={<EnviromentView />} />
                            <Route path=":id/edit" element={<EnviromentEdit />} />
                        </Route>
                        <Route path="permission" element={<Outlet />} >
                            <Route path="" element={<PermissionList />} />
                            <Route path="add" element={<PermissionAdd />} />
                            <Route path="trash" element={<PermissionTrash />} />
                            <Route path=":id" element={<PermissionView />} />
                            <Route path=":id/edit" element={<PermissionEdit />} />
                        </Route>
                        <Route path="step" element={<Outlet />} >
                            <Route path="" element={<StepList />} />
                            <Route path="add" element={<StepAdd />} />
                            <Route path="trash" element={<StepTrash />} />
                            <Route path=":id" element={<StepView />} />
                            <Route path=":id/edit" element={<StepEdit />} />
                        </Route>
                        <Route path="path" element={<Outlet />} >
                            <Route path="" element={<PathList />} />
                            <Route path="add" element={<PathAdd />} />
                            <Route path="trash" element={<PathTrash />} />
                            <Route path=":id" element={<PathView />} />
                            <Route path=":id/edit" element={<PathEdit />} />
                        </Route>
                        <Route path="account" element={<Outlet />} >
                            <Route path="" element={<AccountList />} />
                            <Route path="add" element={<AccountAdd />} />
                            <Route path="trash" element={<AccountTrash />} />
                            <Route path=":id" element={<AccountView />} />
                            <Route path=":id/edit" element={<AccountEdit />} />
                            <Route path=":id/typeuser" element={<Outlet />} >
                                <Route path="" element={<AccountTypeUserList />} />
                                <Route path="add" element={<AccountTypeUserAdd />} />
                                <Route path="trash" element={<AccountTypeUserTrash />} />
                                <Route path=":id" element={<AccountTypeUserView />} />
                                <Route path=":id/edit" element={<AccountTypeUserEdit />} />
                            </Route>
                            <Route path=":id/user" element={<Outlet />} >
                                <Route path="" element={<AccountUserList />} />
                                <Route path="add" element={<AccountUserAdd />} />
                                <Route path="trash" element={<AccountUserTrash />} />
                                <Route path=":id" element={<AccountUserView />} />
                                <Route path=":id/edit" element={<AccountUserEdit />} />
                            </Route>
                        </Route>
                        <Route path="localreading" element={<Outlet />} >
                            <Route path="" element={<LocalReadingList />} />
                            <Route path="add" element={<LocalReadingAdd />} />
                            <Route path="trash" element={<LocalReadingTrash />} />
                            <Route path=":id" element={<LocalReadingView />} />
                            <Route path=":id/edit" element={<LocalReadingEdit />} />
                        </Route>
                        <Route path="subtypereading" element={<Outlet />} >
                            <Route path="" element={<SubTypeReadingList />} />
                            <Route path="add" element={<SubTypeReadingAdd />} />
                            <Route path="trash" element={<SubTypeReadingTrash />} />
                            <Route path=":id" element={<SubTypeReadingView />} />
                            <Route path=":id/edit" element={<SubTypeReadingEdit />} />
                        </Route>
                        <Route path="typeuser" element={<Outlet />} >
                            <Route path="" element={<TypeUserList />} />
                            <Route path="add" element={<TypeUserAdd />} />
                            <Route path="trash" element={<TypeUserTrash />} />
                            <Route path=":id" element={<TypeUserView />} />
                            <Route path=":id/edit" element={<TypeUserEdit />} />
                        </Route>
                        <Route path="user" element={<Outlet />} >
                            <Route path="" element={<UserList />} />
                            <Route path="add" element={<UserAdd />} />
                            <Route path="trash" element={<UserTrash />} />
                            <Route path=":id" element={<UserView />} />
                            <Route path=":id/edit" element={<UserEdit />} />
                        </Route>
                        <Route path="status" element={<Outlet />} >
                            <Route path="" element={<StatusList />} />
                            <Route path="add" element={<StatusAdd />} />
                            <Route path="trash" element={<StatusTrash />} />
                            <Route path=":id" element={<StatusView />} />
                            <Route path=":id/edit" element={<StatusEdit />} />
                        </Route>
                        <Route path="port" element={<Outlet />} >
                            <Route path="" element={<PortList />} />
                            <Route path="add" element={<PortAdd />} />
                            <Route path="trash" element={<PortTrash />} />
                            <Route path=":id" element={<PortView />} />
                            <Route path=":id/edit" element={<PortEdit />} />
                        </Route>
                        <Route path="shipowner" element={<Outlet />} >
                            <Route path="" element={<ShipownerList />} />
                            <Route path="add" element={<ShipownerAdd />} />
                            <Route path="trash" element={<ShipownerTrash />} />
                            <Route path=":id" element={<ShipownerView />} />
                            <Route path=":id/edit" element={<ShipownerEdit />} />
                        </Route>
                        <Route path="ship" element={<Outlet />} >
                            <Route path="" element={<ShipList />} />
                            <Route path="add" element={<ShipAdd />} />
                            <Route path="trash" element={<ShipTrash />} />
                            <Route path=":id" element={<ShipView />} />
                            <Route path=":id/edit" element={<ShipEdit />} />
                        </Route>
                        <Route path="company" element={<Outlet />} >
                            <Route path="" element={<CompanyList />} />
                            <Route path="add" element={<CompanyAdd />} />
                            <Route path="trash" element={<CompanyTrash />} />
                            <Route path=":id" element={<CompanyView />} />
                            <Route path=":id/edit" element={<CompanyEdit />} />
                        </Route>
                        <Route path="severity" element={<Outlet />} >
                            <Route path="" element={<SeverityList />} />
                            <Route path="add" element={<SeverityAdd />} />
                            <Route path="trash" element={<SeverityTrash />} />
                            <Route path=":id" element={<SeverityView />} />
                            <Route path=":id/edit" element={<SeverityEdit />} />
                        </Route>
                        <Route path="operation" element={<Outlet />} >
                            <Route path="" element={<OperationList />} />
                            <Route path="add" element={<OperationAdd />} />
                            <Route path="trash" element={<OperationTrash />} />
                            <Route path=":id" element={<OperationView />} />
                            <Route path=":id/edit" element={<OperationEdit />} />
                            <Route path=":id/step" element={<OperationStep />} />
                            <Route path=":id/port" element={<Outlet />} >
                                <Route path="" element={<OperationPortList />} />
                                <Route path="add" element={<OperationPortAdd />} />
                                <Route path="trash" element={<OperationPortTrash />} />
                                <Route path=":id" element={<OperationPortView />} />
                                <Route path=":id/edit" element={<OperationPortEdit />} />
                            </Route>
                            <Route path=":id/cargo" element={<Outlet />} >
                                <Route path="" element={<OperationCargoList />} />
                                <Route path="add" element={<OperationCargoAdd />} />
                                <Route path="import" element={<OperationCargoImport />} />
                                <Route path="trash" element={<OperationCargoTrash />} />
                                <Route path=":id" element={<OperationCargoView />} />
                                <Route path=":id/edit" element={<OperationCargoEdit />} />
                            </Route>
                        </Route>
                        <Route path="typecargo" element={<Outlet />} >
                            <Route path="" element={<TypeCargoList />} />
                            <Route path="add" element={<TypeCargoAdd />} />
                            <Route path="trash" element={<TypeCargoTrash />} />
                            <Route path=":id" element={<TypeCargoView />} />
                            <Route path=":id/edit" element={<TypeCargoEdit />} />
                            <Route path=":id/category" element={<Outlet />} >
                                <Route path="" element={<CategoryList />} />
                                <Route path="add" element={<CategoryAdd />} />
                                <Route path="trash" element={<CategoryTrash />} />
                                <Route path=":id" element={<CategoryView />} />
                                <Route path=":id/edit" element={<CategoryEdit />} />
                            </Route>
                            <Route path=":id/part" element={<Outlet />} >
                                <Route path="" element={<PartList />} />
                                <Route path="add" element={<PartAdd />} />
                                <Route path="trash" element={<PartTrash />} />
                                <Route path=":id" element={<PartView />} />
                                <Route path=":id/edit" element={<PartEdit />} />
                            </Route>
                            <Route path=":id/breakdown" element={<Outlet />} >
                                <Route path="" element={<BreakdownList />} />
                                <Route path="add" element={<BreakdownAdd />} />
                                <Route path="trash" element={<BreakdownTrash />} />
                                <Route path=":id" element={<BreakdownView />} />
                                <Route path=":id/edit" element={<BreakdownEdit />} />
                            </Route>
                            <Route path=":id/field" element={<Outlet />} >
                                <Route path="" element={<FieldList />} />
                                <Route path="add" element={<FieldAdd />} />
                                <Route path="trash" element={<FieldTrash />} />
                                <Route path=":id" element={<FieldView />} />
                                <Route path=":id/edit" element={<FieldEdit />} />
                            </Route>
                        </Route>
                        <Route path="support" element={<></>} />
                        <Route path="log" element={<></>} />
                        <Route path="settings" element={<></>} />
                    </Route>

                    {/* ROTAS DE CONTROLE */}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        )

    }

}

export default AppStack;